import { useTranslation } from 'react-i18next';
import type { Deck } from '../../api/model';
import classes from './FlashcardsBoard.module.css';
import clsx from 'clsx';
import { Typography } from '../../atoms';
import { Button, Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { DeckTitleDisplay } from '../../molecules';
import { useState } from 'react';
import { ArrowsCollapse, ArrowsExpand } from 'react-bootstrap-icons';

type FlashcardsDeckDisplayProps = {
  deck: Deck,
  index: number,
  searchQuery: string,
  addMode?: boolean,
  selectingMode?: boolean,
  editDeckMode?: boolean,
  onEditDeckClicked: (index: number) => void,
  onDeleteDeckClicked: (index: number) => void,
  onSelectAllFlashcardsFromADeck: (index: number) => void,
  isAllFlashcardsSelectedFromDeck: (index: number) => boolean,
  onAddFlashcard: (deckIdx?: number, flashcardId?: string) => void,
  onSelectFlashcard: (flashcardId: string) => void,
  flashcardSelected: (flashcardId: string) => boolean,
};

function FlashcardsDeckDisplay({
  deck,
  index,
  searchQuery,
  addMode,
  selectingMode,
  editDeckMode,
  onEditDeckClicked,
  onDeleteDeckClicked,
  onSelectAllFlashcardsFromADeck,
  isAllFlashcardsSelectedFromDeck,
  onAddFlashcard,
  onSelectFlashcard,
  flashcardSelected,
}: FlashcardsDeckDisplayProps) {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  if (!addMode && deck.flashcards.length === 0) return null
  return (
    <>
      <div className="d-flex align-items-left mb-3">
        <DeckTitleDisplay
          deckIdx={index}
          name={deck.name}
          count={deck.flashcards.length}
          color={deck.color ?? ''}
          editMode={editDeckMode}
          onEditDeckClicked={onEditDeckClicked}
          onDeleteDeckClicked={onDeleteDeckClicked}
        />
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {t(`flashcards.${ collapsed ? "expand" : "collapse"}`)}
            </Tooltip>
          }
        >
          <Button variant='none' onClick={() => setCollapsed((v) => !v)}>
            { collapsed ? <ArrowsExpand size={18} /> : <ArrowsCollapse size={18}/> }
          </Button>
        </OverlayTrigger>
        {selectingMode && (
          <Button
            variant="link"
            onClick={() => onSelectAllFlashcardsFromADeck(index)}
          >
            {isAllFlashcardsSelectedFromDeck(index)
              ? t('common.deselectAll')
              : t('common.selectAll')}
          </Button>
        )}
      </div>
      {
        !collapsed && (
          <Row
            className={clsx(
              classes.flashcardsContainer,
              'align-items-left',
            )}
          >
            {deck.flashcards.map((flashcard) => (
              <Col className={clsx(classes.flashcardItem, 'col-2')}>
                <Card
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  className={clsx(
                    selectingMode && flashcardSelected(flashcard.id!)
                      ? 'border border-3 border-success'
                      : null,
                  )}
                  onClick={() => {
                    if (selectingMode) {
                      onSelectFlashcard(flashcard.id!)
                    } else {
                      onAddFlashcard(index, flashcard.id)
                    }
                  }}
                >
                  <Card.Body>
                    <div
                      className={clsx(
                        classes.flashcard,
                        'd-flex justify-content-center',
                      )}
                    >
                      <Typography variant="span">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: flashcard.frontside_text ?? '',
                          }}
                        />
                      </Typography>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            {addMode && !searchQuery && (
              <Col className={clsx(classes.flashcardItem, 'col-2')}>
                <Card
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  className={clsx(
                    'bg-light',
                    'border-white',
                    'shadow-none',
                  )}
                  onClick={() => onAddFlashcard(index)}
                  data-testid={`add-new-flashcard-${index+1}-button`}
                >
                  <Card.Body>
                    <div
                      className={clsx(
                        classes.flashcard,
                        'd-flex align-items-center justify-content-center',
                      )}
                    >
                      <div>
                        <Typography>
                          {t('flashcards.addFlashcard')}
                        </Typography>
                        <Typography variant="h3">+</Typography>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        )
      }
    </>
  );
}

export default FlashcardsDeckDisplay;
